/**
    * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
    * http://cssreset.com
    */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /*font-size: 100%;*/
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  zoom: 1;
}

ul,
ol,
li {
  zoom: normal;
  list-style-type: none !important;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-family: 'ＭＳ Ｐゴシック', YuGothic, 'æ¸¸ã‚´ã‚·ãƒƒã‚¯', 'Yu Gothic',
    'ãƒ¡ã‚¤ãƒªã‚ª', sans-serif;
  font-weight: 500;
  color: #333;
  font-size: 1.6rem;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*reset.css*/

img,
a {
  border: 0;
}
a {
  outline: none;
}
a:hover {
}

a:link {
  color: #333;
}
a:visited {
  color: #333;
}
a:hover {
  color: #333;
}

.fL {
  float: left;
}
.fR {
  float: right;
}
.clear {
  float: none;
}

.txtL {
  text-align: left;
}
.txtC {
  text-align: center;
}
.txtR {
  text-align: right;
}

img {
  vertical-align: bottom;
  height: auto !important;
}

/*-----------------------------------------------------
						clearfix
-----------------------------------------------------*/
.clearfix:after {
  content: '.'; /* æ–°ã—ã„è¦ç´ ã‚’ä½œã‚‹ */
  display: block; /* ãƒ–ãƒ­ãƒƒã‚¯ãƒ¬ãƒ™ãƒ«è¦ç´ ã« */
  clear: both;
  height: 0;
  visibility: hidden;
}

.clearfix {
  min-height: 1px;
}

* html .clearfix {
  height: 1px;
}
/*-----------------------------------------------------
						form reset
-----------------------------------------------------*/
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='submit'],
input[type='button'],
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

/*-----------------------------------------------------
						å…ƒ
-----------------------------------------------------*/
body {
  font-family: 'ＭＳ Ｐゴシック',
    /* æ¸¸ã‚´ã‚·ãƒƒã‚¯ãŒå…¥ã£ã¦ã„ãªã„Windows */ sans-serif;
}

/* Windows */
@font-face {
  font-family: 'ＭＳ Ｐゴシック';
  font-weight: normal;
  src: local('ＭＳ Ｐゴシック'),
}
@font-face {
  font-family: 'HG ゴシック E';
  font-weight: bold;
  src: local('HG ゴシック E'), /* Boldã¯ãã®ã¾ã¾ */
}

ul {
  list-style-type: none;
  padding: 0;
}

li.sub-menu {
  display: block;
  width: 90%;
  border: solid black 1px;
  margin: none;
  padding: 5px;
  background-color: aquamarine;
  text-align: center;
}

a {
  text-decoration: none;
}

.top-bar {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 90%;
}

h1 {
  margin: 1px;
}
button {
  display: block;
}

.btn-left {
  float: left;
  margin-left: 1%;
}

#showData {
  font: 16px;
}

table {
  table-layout: fixed;
  border: solid 1px #ddd;
  border-collapse: collapse;
  padding: 2px 3px;
  text-align: center;
}

tbody {
  display: block;
  overflow: scroll;
  height: 200px;
  width: 100%;
}

tr {
  display: block;
}

th,
td {
  overflow: hidden;
  border: solid 1px #ddd;
  width: 100px;
}

div {
  padding: 0;
  margin: 0;
  border: 0;
}

.rd {
  border: transparent;
}

.num {
  text-align: right;
}

.amount,
.days,
.total-amount,
.total-days {
  text-align: right;
}

.kigo {
  float: left;
  margin-left: 2px;
  margin-right: 2px;
}

.popup_bg {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
}

.popup_content {
  width: 100%;
  height: 93vh;
  background-color: #fff;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  /* background-color: #fcf2e3; */
  height: 96px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

h2 {
  font-size: 1.6rem;
  color: #fff;
  background-color: #4065ac; /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
  display: inline-block;
  padding: 4px 16px;
  border-radius: 0 0 10px 0;
}

button {
  font-size: 1.4rem;
  background-color: #fff799;
  color: #2d2d2d;
  padding: 4px 16px;
  border-radius: 4px;
  white-space: nowrap;
  transition: all 0.3s;
  border: 2px solid #acacac;
}

button:hover {
  background-color: rgba(241, 195, 74, 0.5);
}

button.btn_primary {
  padding: 4px 8px;
}

button.btn_primary:hover {
  background-color: rgba(241, 195, 74, 0.8);
}

.top_bar_primary_btn_wrap {
  display: -webkit-flex;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 16px;
  margin-left: 42px
}

.top_bar_primary_btn_wrap button {
  margin-left: 16px;
}

.top_bar_secondary_btn_wrap {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
}

.top_bar_secondary_btn_wrap button {
  margin-left: 16px;
  padding: 4px 8px;
  background-color: #ccc;
}

.main {
  margin-top: 96px;
  min-width: 1200px;
  overflow-x: auto;
  /* background-color: #f0fbfe; */
}

input,
select,
textarea {
  border: 2px solid #a8a8a8;
  border-radius: 4px;
  padding: 1px 8px;
  position: relative;
  z-index: 1;
  transition: all 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border: 2px solid #4065ac; /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
}

input.rd:focus,
select.rd:focus,
textarea.rd:focus {
  border: none;
}

input,
textarea {
  background-color: #fff;
}

input.rd,
textarea_rd {
  background-color: transparent;
}

input[type='button'] {
  background-color: #ccc;
  padding: 2px 4px;
}

.input_required {
  border: 2px solid rgba(64, 101, 171, 0.5); /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
  background-color: rgba(64, 101, 171, 0.1); /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
}

.basic_data {
  padding: 0 0 4px;
}

.row {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row2 {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row_half {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row_right {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.row_left {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row_right_single {
  display: -webkit-flex;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.row,
.row_half,
.row_right,
.row_left,
.row_right_single {
  padding: 0 16px;
  border-bottom: 1px dotted #d5d5d5;
}

.basic_data .row,
.basic_data .row_half,
.basic_data .row_right,
.basic_data .row_left,
.basic_data .row_right_single {
  padding-bottom: 0;
  border: none;
}

.main .row:last-child {
  border-bottom: none;
}

.non_bd {
  border-bottom: none;
}

.left {
  width: 33%;
}

.left2 {
  width: 26%;
}

.center {
  width: 33%;
}

.center2 {
  width: 29%;
}

.right {
  width: 33%;
}

.right2 {
  width: 27%;
}

.more_right {
  width: 26%;
}

.one {
  width: 28%;
}

.two {
  width: 22%;
}

.three {
  width: 28%;
}

.four {
  width: 22%;
}

.threefour {
  width: 50%;
}

.full {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label_top {
  align-items: flex-start;
}

.half_left {
  width: 33%;
}

.half_right {
  width: 33%;
}

.half_third_left {
  width: 53%;
}

.half_third_right {
  width: 25%;
}

.right_right {
  width: 33%;
  margin-left: 0.5%;
}

.right_left {
  width: 33%;
}

.left_left {
  width: 33%;
}

.left_right {
  width: 33%;
}

.user_master_btn_wrap {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.user_master_btn_wrap button {
  margin-left: 16px;
}

.input_4 {
  width: 60px;
}

.input_6 {
  width: 80px;
}

.input_10 {
  width: 110px;
}

.input_10h {
  width: 110px;
  visibility: hidden;
}

.input_16 {
  width: 150px;
}

.input_name {
  width: 280px;
}

.input_status {
  width: 180px;
}

textarea {
  width: calc(100% - 160px);
  height: 110px;
}

textarea.rd {
  background-color: #f0fbfe;
}

.left label,
.center label,
.right label,
.full label,
.right_left label,
.right_right label,
.left_left label,
.left_right label,
.half_left label,
.half_right label,
.more_right label,
.one label,
.two label,
.three label,
.four label,
.threefour label {
  width: 150px;
  display: inline-block;
  text-align: right;
  margin-right: 8px;
  color: #4065ac; /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
}

a:link {
  color: #4065ac;
} /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
a:visited {
  color: #4065ac;
} /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/
a:hover {
  color: #4065ac;
} /*ãƒªã‚³ãƒ¼ã‚«ãƒ©ãƒ¼*/

.right label.label_lg {
  width: 180px;
}

.select_wrap {
  display: inline-block;
  position: relative;
  background-color: #fff;
}

.select_wrap::before {
  content: '';
  border-bottom: 2px solid #333333;
  border-left: 2px solid #333333;
  right: 12px;
  height: 8px;
  margin-top: -7px;
  transform: rotate(-45deg);
  position: absolute;
  top: 50%;
  width: 8px;
  /* z-index: 2; */
}

.select_wrap select {
  padding-right: 32px;
}

.required {
  color: #f00;
  font-size: 2rem;
}

label a {
  text-decoration: underline;
}

.attention {
  font-size: 1.6rem;
  text-align: right;
  padding-right: 16px;
}

a.js-modal {
  font-size: 2.4rem;
  background-color: #555;
  color: #fff;
  padding: 4px 16px;
  border-radius: 4px;
  white-space: nowrap;
  transition: all 0.3s;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  display: none;
}

.payday {
  padding: 16px 16px;
}
